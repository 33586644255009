<template>
  <div class="service">
    <van-nav-bar
      :fixed="true"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :title="$t('Financing.ServiceSu.Online')"
      class="nav-bar"
    />
    <!-- 对话框 -->
    <div class="kotak" ref="kotak">
      <div v-for="item in duihua" :key="item.id">
        <!-- 左面 -->
        <div v-if="item.type === 'back'" class="kotak-box">
          <div class="img-box">
            <img src="@/assets/Img/Service/layanan.png" alt="" />
          </div>
          <div class="layanan" v-if="item.chatType === 'text'">
            {{ item.content }}
            <!-- 左面箭头 -->
            <div class="kiri">
              <img src="@/assets/Img/Service/kiri.png" alt="" />
            </div>
          </div>
          <!-- 显示图片 -->
          <div
            @click="imagePreview(item)"
            class="layanan layanan-img"
            v-if="item.chatType === 'img'"
          >
            <img :src="item.content" alt="" />
          </div>
        </div>
        <!-- 右面 -->
        <div v-if="item.type === 'user'" class="kotak-box1">
          <div class="pengguna" v-if="item.chatType === 'text'">
            {{ item.content }}
            <!-- 右面箭头 -->
            <div class="kanan">
              <img src="@/assets/Img/Service/kanan.png" alt="" />
            </div>
          </div>
          <!-- 显示图片 -->
          <div
            @click="imagePreview(item)"
            class="pengguna pengguna-img"
            v-if="item.chatType === 'img'"
          >
            <img :src="item.content" alt="" />
          </div>
          <div class="img-box">
            <img :src="headUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 对话输入框 -->
    <div class="dialogue">
      <div class="input-div">
        <input v-model="textarea" type="text" :placeholder="$t('Financing.ServiceSu.Please')" />
      </div>
      <div class="upload-img">
        <img src="@/assets/Img/Service/tp.png" alt="" />
        <div class="input-file">
          <input
            name="file"
            type="file"
            @change="uploadEvent()"
            ref="uploading"
          />
        </div>
      </div>
      <div>
        <button @click="SendEvent(1)">
          {{ $t('Financing.ServiceSu.send') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { NavBar, ImagePreview } from 'vant'
import mqtt from 'mqtt' // 引入mqtt
import pako from 'pako' // 引入对数据进行处理转换的
import { REQUEST_API } from '@/http/api'
import axios from 'axios' //引入axios依赖
export default {
  name: 'DialoguePage',
  data() {
    return {
      DataID: '', // 获取定于ID
      headUrl: '', // 用户头像
      textarea: '', // 聊天数据
      duihua: [], // 数据模拟
      language: '', // 语言
      connection: {
        host: JSON.parse(localStorage.getItem('user-info'))[1].data.MqttUrl,
        port: JSON.parse(localStorage.getItem('user-info'))[1].data.MqttWsPort,
        clean: true, // 保留会话
        endpoint: '/mqtt',
        connectTimeout: 20000, // 超时时间
        reconnectPeriod: 1000, // 重连时间间隔
      },
      // 七牛云需要的参数
      SevenNiuYun: {
        token: '',
        key: '',
        type: false,
        url: '', // 上传成功返回的url
        status: 'text',
      },
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  watch: {
    duihua(val) {
      this.$nextTick(() => {
        this.positionSize()
      })
    },
  },
  created() {
    this.createConnection()
    this.DataID = String(this.module.PersonalEvnet()[0].data.info.id)
  },
  updated() {},
  mounted() {
    this.language = localStorage.getItem('language')
    this.pertanyaan()
    if (this.module.PersonalEvnet()[1].data.player.icon) {
      this.headUrl = this.module.PersonalEvnet()[1].data.player.icon
    } else {
      this.headUrl = require('@/assets/Img/myImg/head.png')
    }
    this.ImgToken()
  },
  methods: {
    //图片预览
    imagePreview(data) {
      ImagePreview({
        images: [data.content],
        showIndex: false,
      })
    },
    //请求七牛云token
    ImgToken() {
      let NumData = {
        cmd: '202431',
        uid: this.module.UIDEvent(),
      }
      REQUEST_API(NumData).then((data) => {
        if (data.data.sc == 0) {
          this.SevenNiuYun.token = data.data.notifyList[0].data.token
          console.log(this.SevenNiuYun.token)
        }
      })
    },
    //发送图片
    uploadEvent() {
      let timeData = Date.parse(new Date())
      this.SevenNiuYun.key = 'CHAT' + this.module.UIDEvent() + timeData
      console.log(this.SevenNiuYun.key)
      let text, state, time
      let _this = this
      let formData = new FormData()
      //利用formData的方式获取数据，并且转为表单提交的形式
      formData.append('file', this.$refs.uploading.files[0])
      formData.append('key', this.SevenNiuYun.key)
      formData.append('token', this.SevenNiuYun.token)
      axios({
        url: 'http://upload-z2.qiniup.com/', //请求地址
        method: 'post', //请求方式
        data: formData,
      })
        .then((resp) => {
          console.log(resp);
          //请求成功的回调函数
          _this.SevenNiuYun.type = true
          _this.SevenNiuYun.url = 'http://file.xiud55.top/' + resp.data.key
          this.textarea = _this.SevenNiuYun.url
          this.loadingImg = false
          text = '上传成功'
          state = 'success'
          time = '1000'
          this.SendEvent(2)
        })
        .catch((error) => {
          _this.SevenNiuYun.type = false
          this.loadingImg = false
          text = '上传失败'
          state = 'error'
          time = '1000'
        })
    },
    //页面显示位置
    positionSize() {
      if (this.$refs['kotak']) {
        this.$refs['kotak'].scrollTop = this.$refs['kotak'].scrollHeight
      }
    },
    //查询消息接口
    pertanyaan() {
      let data = {
        cmd: 201203,
        uid: this.module.UIDEvent(),
        language: this.language,
      }
      REQUEST_API(data).then((res) => {
        this.duihua = res.data.notifyList[0].data.reverse()
      })
    },
    //  聊天窗口的发送事件
    SendEvent(index) {
      if (index === 1) {
        this.SevenNiuYun.status = 'text'
      } else {
        this.SevenNiuYun.status = 'img'
      }
      let test = this.textarea
      //为空或全部为空格
      if (test.match(/^[ ]*$/)) {
        this.windowArr.textarea = ''
      } else {
        if (this.textarea != '') {
          this.loadingSend = true
          // 获取发送当前时间
          let d = new Date()
          let my_hours = d.getHours()
          let my_minutes = d.getMinutes()
          let my_seconds = d.getSeconds()
          let time = my_hours + ':' + my_minutes + ':' + my_seconds
          // 发送消息给后端
          let NumData = {
            cmd: '201202',
            uid: this.module.UIDEvent(),
            content: this.textarea,
            chat_type: this.SevenNiuYun.status,
          }
          REQUEST_API(NumData)
            .then((data) => {
              if (data.data.sc == 0) {
                this.textarea = ''
              }
              // if (data.data.sc == 0) {
              //   this.windowArr.textarea = ''
              // } else {
              //   text = '发送失败'
              //   state = 'error'
              //   time = '1000'
              //   this.module.MessageEvent(text, state, time)
              // }
              // this.SevenNiuYun.status = 'text'
              // this.loadingSend = false
            })
            .catch((error) => {
              // this.SevenNiuYun.status = 'text'
              // this.loadingSend = false
            })
        }
      }
    },
    //mqtt
    createConnection() {
      let _this = this
      /// 连接字符串, 通过协议指定使用的连接方式   // ws 未加密 WebSocket 连接
      // wss 加密 WebSocket 连接  // mqtt 未加密 TCP 连接   // mqtts 加密 TCP 连接
      // wxs 微信小程序连接 // alis 支付宝小程序连接
      const { host, port, endpoint, ...options } = this.connection
      console.log('connection', this.connection)
      const connectUrl = `ws://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      // 链接成功时触发
      this.client.on('connect', () => {
        _this.ScrollBar(this.DataID, 0)
      })
      //  链接异常时触发
      this.client.on('error', (error) => {
        // console.log('链接异常', error)
      })
      // 断开发起重连
      this.client.on('reconnect', (error) => {
        // console.log('正在重连:', error)
      })
      // 接受服务器数据时触发
      this.client.on('message', (topic, message) => {
        this.dataStatus(message)
      })
    },
    //订阅主题
    ScrollBar(topic, qos) {
      let _this = this
      this.client.subscribe(topic, qos, (error, res) => {
        if (error) {
          return
        }
      })
    },
    // 转换聊天信息数据
    dataStatus(b64Data) {
      let strData = atob(b64Data)
      let charData = strData.split('').map(function (x) {
        return x.charCodeAt(0)
      })
      let binData = new Uint8Array(charData)
      let data = pako.inflate(binData)
      this.Utf8ArrayToStr(data)
    },
    Utf8ArrayToStr(array) {
      let out, i, len, c
      let char2, char3
      out = ''
      len = array.length
      i = 0
      while (i < len) {
        c = array[i++]
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c)
            break
          case 12:
          case 13:
            char2 = array[i++]
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
            break
          case 14:
            char2 = array[i++]
            char3 = array[i++]
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            )
            break
        }
      }
      out = JSON.parse(out)
      console.log(out)
      this.duihua.push(out)
      // out.time = this.module.timestampToTime(out.time)//时间
      // let inKey = ''
      // this.ChatArrContent.forEach((_) => {
      //   inKey = _.uid
      // })
      // if (inKey === out.uid) {
      //   this.ChatArrContent.push(out)
      // }
    },
    //返回按钮
    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="less" scoped>
.service {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
//对话框央视
.kotak {
  width: 100vw;
  height: calc(100vh - 60px - 3rem);
  background-color: #dddbdb;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  .img-box {
    width: 3.5rem;
    height: 3.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .kotak-box {
    width: 100vw;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  // 左面框
  .layanan {
    max-width: 50%;
    margin-left: 10px;
    text-align: left;
    padding: 5px;
    font-size: 1rem;
    word-break: break-all;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    position: relative;
  }
  // 左面箭头
  .kiri {
    position: absolute;
    left: -0.5rem;
    top: 0.2rem;
  }
  .kotak-box1 {
    width: calc(100vw - 10px);
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  // 右面框
  .pengguna {
    max-width: 50%;
    // text-align: right;
    // margin-left: calc(100% - 50% - 3.5rem - 10px);
    margin-right: 10px;
    padding: 5px;
    text-align: left;
    font-size: 1rem;
    border: 1px solid white;
    background-color: white;
    border-radius: 0.2rem;
    word-break: break-all;
    position: relative;
  }
  .kanan {
    position: absolute;
    right: -0.5rem;
    top: 0.2rem;
  }
}
.dialogue {
  width: 100vw;
  min-height: 3rem;
  max-height: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .input-div {
    height: 100%;
    display: flex;
    align-items: center;
    input {
      width: 50vw;
      height: 50%;
      margin-left: 3vw;
      border-radius: 1.2rem;
      padding: 0.2rem 0.5rem;
      border: 1px solid;
      font-size: 0.8rem;
    }
  }
  .upload-img {
    width: 2rem;
    height: 2rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .input-file {
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      input {
        width: 100%;
        height: 100%;
      }
    }
  }
  button {
    width: 25vw;
    min-height: 2rem;
    color: white;
    border: 1px solid #1f9eff;
    border-radius: 0.2rem;
    background-color: #1f9eff;
    padding: 0.2rem;
    font-size: 0.8rem;
  }
}
.layanan-img,
.pengguna-img {
  width: 5rem;
  height: 5rem;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>